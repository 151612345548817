<template>
    <div class="modules">
        <div class="transaction-container">
            <div class="summary">
                <h1 class="title"> {{ $t("lang.teamwork") }}</h1>
                <div class="tools">
                    
                </div>
            </div>
            <div class="card">
                <div class="bd">
                    <div class="table-wrapper">
                        <div class="table-tools">
                            <!-- <span class="demonstration">默认</span> -->
                            <el-date-picker  v-model="statsDate"  type="month" format="yyyyMM" value-format="yyyyMM" @change="fetchUserPlatform"  :picker-options="pickerOptions">  </el-date-picker>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="bd">
                    <div class="table-wrapper">
                        <div class="table-tools">
                            <div>{{ $t("lang.Referral") }}</div>
                        </div>
                        <div   class="table-inner">
                            <div class="tab">{{ $t("lang.downlines") }}</div>
                            <div class="tab">{{ $t("lang.contributors") }}</div>
                            <div class="tab">{{ $t("lang.Completed") }}</div>
                            <div class="tab">{{ $t("lang.Expected") }}</div>
                            <div class="tab">{{ $t("lang.settled") }}</div>
                        </div>
                        <div   class="table-inner">
                            <div>{{allot.allUserCnt || 0}}</div>
                            <div>{{allot.posterityUserCnt || 0}}</div>
                            <div >{{allot.posterityGameCnt || 0}}</div>
                            <div @click="goTransaction(allot)">{{allot.yield || 0}}</div>
                            <div @click="goTransaction(allot)">{{allot.alloted ==null ? '':allot.alloted ? $t("lang.sed"):$t("lang.unSed") }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="solid"></div>
            
            <div class="card">
                <div class="bd">
                    <div class="table-wrapper">
                        <div class="table-tools">
                            <div>{{ $t("lang.community") }}</div>
                        </div>
                        <div   class="table-inner">
                            <div class="tab">{{ $t("lang.downlines") }}</div>
                            <div class="tab">{{ $t("lang.contributors") }}</div>
                            <div class="tab">{{ $t("lang.Completed") }}</div>
                            <div class="tab">{{ $t("lang.Sessions") }}</div>
                            <div class="tab">{{ $t("lang.Expected") }}</div>
                            <div class="tab">{{ $t("lang.settled") }}</div>
                        </div>
                        <div   class="table-inner">
                            <div>{{comAllot.allUserCnt || 0}}</div>
                            <div>{{comAllot.posterityUserCnt || 0}}</div>
                            <div >{{comAllot.posterityGameCnt || 0}}</div>
                            <div>{{comAllot.gameGapCnt || 0}}</div>
                            <div  @click="goTransaction(comAllot)">{{comAllot.yield || 0}}</div>
                            <div  @click="goTransaction(comAllot)">{{comAllot.alloted ==null ? '':comAllot.alloted ? $t("lang.sed") :$t("lang.unSed") }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="solid"></div>

            <div class="card">
                <div class="bd">
                    <div class="table-wrapper">
                        <div class="table-tools">
                            <div>{{ $t("lang.SuperNode") }}</div>
                        </div>
                        <div   class="table-inner">
                            <div class="tab">{{ $t("lang.downlines") }}</div>
                            <div class="tab">{{ $t("lang.contributors") }}</div>
                            <div class="tab">{{ $t("lang.Completed") }}</div>
                            <div class="tab">{{ $t("lang.Sessions") }}</div>
                            <div class="tab">{{ $t("lang.Expected") }}</div>
                            <div class="tab">{{ $t("lang.settled") }}</div>
                        </div>
                        <div   class="table-inner">
                            <div>{{superAllot.allUserCnt || 0}}</div>
                            <div>{{superAllot.posterityUserCnt || 0}}</div>
                            <div >{{superAllot.posterityGameCnt || 0}}</div>
                            <div>{{superAllot.gameGapCnt || 0}}</div>
                            <div @click="goTransaction(superAllot)">{{superAllot.yield || 0}}</div>
                            <div @click="goTransaction(superAllot)">{{superAllot.alloted ==null ? '':superAllot.alloted ? $t("lang.sed"):$t("lang.unSed")}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="solid"></div>
        </div>
    </div>
    </template>
    <script>
    import {BASE_GLOB_API} from "@/config.js";
    import {axiosGet,axiosPost} from '@/api/api';
    import moment from "moment";
    import EventBus from '@/utils/EventBus'
    export default {
        name:'teamwork',
        data(){
            return {
                url:{
                    stats:BASE_GLOB_API+'/revow/invite/stats',
                   
                },
                statsDate: new Date(), // 设置默认值为当前日期
                allot:{
                    gameGapCnt:0,
                    posterityGameCnt:0,
                    posterityUserCnt:0,
                    yield:0,
                    allUserCnt:0,
                    allotId:'',
                    alloted:null
                },
                comAllot:{
                    gameGapCnt:0,
                    posterityGameCnt:0,
                    posterityUserCnt:0,
                    yield:0,
                    allUserCnt:0,
                    allotId:'',
                    alloted:null
                },
                superAllot:{
                    gameGapCnt:0,
                    posterityGameCnt:0,
                    posterityUserCnt:0,
                    yield:0,
                    allUserCnt:0,
                    allotId:'',
                    alloted:null
                },
                pickerOptions: {
                    disabledDate(time) {
                    return time.getMonth() < 4; // 禁用5月份及之前的日期
                    }
                },
            }
        },
        mounted(){
            
        },
        created(){
            this.lang = sessionStorage.getItem("Language")
            EventBus.$on('share', value => {
          // 将 A 组件传递过来的数据保存到 B 组件
                console.log("value123",value)
               if(value){
                this.fetchUserPlatform()
               
               }
    
            })
           
            this.fetchUserPlatform()
           
        },
        beforeDestroy() {
        // 移除监听事件 "share"
           EventBus.$off('share')
        },
        methods:{
            goTransaction(value){
                if(value.allotId && value.alloted){
                    this.$router.push({name:'transaction', params:{thirdFlowNo:value.allotId}})
                }
             
            },
            fetchUserPlatform(){
                let date = moment(this.statsDate).format("yyyyMM")
                axiosPost(this.url.stats,{statsDate:date}).then(res=>{
                   if(res.code ==200){
                     let date = res.result
                     if(date.allot){
                        this.allot = date.allot
                     }else{
                        this.allot = {}
                     }
                     if(date.comAllot){
                        this.comAllot = date.comAllot
                     }else{
                        this.comAllot = {}
                     }
                     if(date.superAllot){
                        this.superAllot = date.superAllot
                     }else{
                        this.superAllot = {}
                     }

                // this.allot={
                //     gameGapCnt:0,
                //     posterityGameCnt:0,
                //     posterityUserCnt:0,
                //     yield:0,
                //     allUserCnt:0,
                //     allotId:'GR99_1779708117429428226',
                //     alloted:true
                // }

                   }else{
                        this.$message.error(res.message);
                    }
                })
               
            }, 
           
         
            handleSizeChange(val) {
                //console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                //console.log(`当前页: ${val}`);
                this.init(val, this.ipagination.pageSize);
            },
    
        }
    }
    </script>
    <style lang="less" scoped>
    .transaction-container {
        .summary {
            background:#f4f5f6; border-radius:4px; padding:30px; display:flex; justify-content: space-between; align-items: center; margin-bottom:30px;
        }
        .box-wrapper {
            display:flex; justify-content: space-between; align-items: flex-start;
            .box-main {
                flex:1;
                .form-item {
    
                }
                .form-item-help {
                    display:flex; justify-content: space-between; align-items: flex-start; flex-wrap:wrap; margin:10px -5px;
                    .meta {
                        width:50%; line-height:23px; padding:5px;
                        .meta-title {color:#999;}
                        .meta-val {color:#333; font-weight:700;}
                    }
                }
            }
            .box-side {
                width:300px; min-width:300px; margin-left:50px;
                .mod {
                    .bd {
                        .list {
                            .item {
                                display:flex; justify-content: flex-start; align-items: flex-start; padding:4px 0;
                                .item-icon {
                                    background:url(~@/assets/img/icon_text.svg) no-repeat center center/contain; width:12px; height:12px; margin:4px 6px 0 0;
                                }
                                .item-text {
                                    font-size:14px; color:#666; cursor: pointer;
                                    &:hover {
                                        color:#4458fe; text-decoration: underline; opacity: .8;
                                    }
                                }
                                &.item-video {
                                    .item-icon {
                                        background-image:url(~@/assets/img/icon_video.svg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .card {
             
            .bd {
                .table-wrapper {
                    padding:10px 0;
                    .table-tools {
                        text-align: center;
                        
                    }
                   .table-inner{
                      display: flex;
                      justify-content: space-around;
                      .tab{
                        padding: 30px 0;
                      }
                   }
                }
            }
        }
        .solid{
            padding: 20px 0;
            border-bottom: 1px solid black;
        }
    }
    </style>